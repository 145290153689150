import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.css']
})
export class PaymentViewComponent implements OnInit {
  id: number;
  ticketDetail: any;

  constructor(private route: ActivatedRoute, private router: Router, public service: MainService) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
   }

  ngOnInit(): void {
    this.viewTicket();
    this.showUserDetail()
  }
  viewTicket() {
    this.service.get(`stripe/payment/get-payment-byId?userId=${this.id}`).subscribe((res: any) => {
      this.ticketDetail = res.data

    })
  }
  // reply(){
  //   let a = document.createElement('a')
  //   a.href = `mailto:${this.ticketDetail.email}?body=Hello! ${this.ticketDetail.firstName}`
  //   a.click()
  // }
  userDetail:any
    // api of general information
    showUserDetail() {
      this.service.showSpinner();
      var url = "account/admin/user-management/user-details?userId=" + this.id;
      this.service.get(url).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.userDetail = res.data;
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
    }
}
