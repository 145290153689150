<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW PAYMENT    </h1>
        </div>
       
        <!-- tab General -->
       
        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style" >
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Username</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.firstName}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Email</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.email}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">User Id</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{ticketDetail?.userId}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Payment Id</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{ticketDetail?.paymentId}}</label>
                            </div>
                         
                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Card Number</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{ticketDetail?.nubmer}}</label>
                            </div>
                        </div> -->
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Amount</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.amount}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Currency</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.currency}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Customer Id</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.customer}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">TxnId</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.txnId}}</label>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Card Id</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.cardId}}</label>
                            </div>
                        </div> -->

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Payment Status</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{ticketDetail?.paymentStatus}}</label>
                            </div>
                        </div>


                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Date & Time</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{ticketDetail?.createTime | date :'medium'}}</label>
                            </div>
                        </div>



                      

                     

                     
                    </div>
                    <div class="text-left mt40 text-center">
                        <a routerLink="/payment-list" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                        <!-- <a (click)="reply()" class="btn btn-large  max-WT-200 font-100 btn-green ml-2">Reply</a> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- tab genral end -->

        
        <!-- tab login session end -->

    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>