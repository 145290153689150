<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">Edit Profile</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto ">
                <div class="text-left d-flex justify-content-end" >
                    <button type="button" [routerLink]="['/change-password']"  class="btn btn-lg btn-success ">Change
                        Password</button>
                </div>
                <div class="main-block-innner mb40 ">

                    <div class="add-store-block input-style">
                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="editImage" class="profile-pic" alt="profile image">
                                <label class="upload-label">
                                    <input type="file" class="hidden" accept=".png, .jpg, .jpeg"
                                        (change)="uploadImg($event)" />
                                    <i class="fas fa-camera" aria-hidden="true"></i>
                                </label>
                            </div>
                        </div>
                        <form [formGroup]="editForm">

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Name </label>
                                <div class="col-md-1 sm-d-none"> :</div>
                                <div class="col-md-6">
                                    <input type="text" formControlName="name" placeholder="Enter Name"
                                        class="form-control" maxlength="30" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('name').hasError('required') && (editForm.get('name').dirty || editForm.get('name').touched )"
                                            class="error" padding>*Please enter name.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('name').hasError('pattern') && editForm.get('name').dirty"
                                            class="error" padding>*Please enter valid name.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Email </label>
                                <div class="col-md-1 sm-d-none"> :</div>
                                <div class="col-md-6">
                                    <input type="text" disabled placeholder="Enter Email" class="form-control"
                                        maxlength="64" (keypress)="service.preventSpace($event)"
                                        formControlName="email" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('required') && (editForm.get('email').dirty || editForm.get('email').touched )"
                                            class="error" padding>*Please enter email.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('pattern') && editForm.get('email').dirty"
                                            class="error" padding>*Please enter valid email.</p>
                                    </span>

                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Mobile Number</label>
                                <div class="col-md-1 sm-d-none"> :</div>
                                <div class="col-md-6">
                                    <input type="tel" formControlName="phone" placeholder="Enter Mobile Number"
                                        class="form-control" maxlength="15" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('phone').hasError('required') && (editForm.get('phone').dirty || editForm.get('phone').touched )"
                                            class="error" padding>*Please enter mobile number.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('phone').hasError('pattern') && editForm.get('phone').dirty"
                                            class="error" padding>*Please enter valid mobile number.</p>
                                    </span>

                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Date of Birth </label>
                                <div class="col-md-1 sm-d-none"> :</div>
                                <div class="col-md-6">
                                    <input type="date" formControlName="DOB" placeholder="DOB"
                                        class="form-control" [max]='this.date'  />
                                    <span class="error">
                                        <p *ngIf="editForm.get('DOB').hasError('required') && (editForm.get('DOB').dirty || editForm.get('DOB').touched )"
                                            class="error" padding>*Please select DOB.</p>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Gender </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-6">
                                    <input type="text" formControlName="gender" placeholder="Enter Gender"
                                        class="form-control" minlength="1" maxlength="7"
                                        (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('gender').hasError('required') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                            class="error" padding>*Please enter gender.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('gender').hasError('pattern') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                            class="error" padding>*Please enter valid gender.</p>
                                    </span>
                                </div>
                            </div> -->
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Address </label>
                                <div class="col-md-1 sm-d-none"> :</div>
                                <div class="col-md-6">
                                    <!-- <input type="text" formControlName="address" placeholder="Enter Address"
                                        class="form-control" minlength="50" maxlength="100"
                                        (keypress)="service.preventSpace($event)" /> -->
                                        <textarea  formControlName="address" placeholder="Enter Address" rows="5" class="form-control" minlength="50" maxlength="100"
                                        (keypress)="service.preventSpace($event)" ></textarea>
                                    <span class="error">
                                        <p *ngIf="editForm.get('address').hasError('required') && (editForm.get('address').dirty || editForm.get('address').touched )"
                                            class="error" padding>*Please enter address.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="text-left mt40">
                                <a class="btn btn-large max-WT-200 font-100 btn-green mr-5"
                                    routerLinkActive="router-link-active" (click)="updateProfile()">Update Profile</a>
                                <a href="javascript:void(0)" class="btn btn-large max-WT-200 font-100 btn-green ml8"
                                    [routerLink]="['/my-profile']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<footer>
    <div class="container-fluid">
        <p class="copyright">Token Development ICO Crypto © 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>