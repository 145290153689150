import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { element } from "protractor";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-kyc-update",
  templateUrl: "./kyc-update.component.html",
  styleUrls: ["./kyc-update.component.css"],
})
export class KycUpdateComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  kycList: any = [];
  userId: any;
  url: string;
  kycAmountLimit: any;
  totalRecords: any;
  itemsPerPage: number = 10;

  text: any = false;
  isAssending: boolean = true;
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.kycForm = new FormGroup({
      status: new FormControl("", Validators.required),
      userName: new FormControl("", Validators.required),
      country : new FormControl("")
    });
    this.kyc();
    // console.log(this.text);
    this.kycStatusChecked();
    this.countryList();
  }

  products: any = [];
  countryList() {
    this.httpClient.get("assets/country.json").subscribe((data) => {
      // console.log(data);
      this.products = data;
      // console.log(this.products)
    });
  }

  // api of kyc listing
  kyc() {
    // var url =
    //   "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //   (this.pageNumber - 1) +
    //   "&pageSize=10";
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}${this.kycForm.value.userName ? "&search=" + this.kycForm.value.userName: ""}${this.kycForm.value.status? "&kycStatus=" + this.kycForm.value.status: ""}${this.kycForm.value.country ? "&country=" + this.kycForm.value.country : ""}`;
this.service.showSpinner()
    this.service.get(url).subscribe(
      (res: any) => {
        // console.log("kyc", res);
        if (res.status == 200) {
          this.kycList = res.data.list;
          
        }
        this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();

      },
      (err) => {
        if (err["status"] == 401) {
        this.service.hideSpinner();

          this.service.toasterErr("Unauthorized Access");
        } else {
        this.service.hideSpinner();

          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  kycRequiredCheckData: any;
  kycRequired() {
    // console.log(this.text);
    // alert(this.text)
    // let kycRequiredCheck = this.coinTokenForm.value.coinNameToken;
    var url =
    "account/check-requried-kyc?checkId=" +
    "1" +
    "&kycIsCheck=" +
    (this.text == true ? "YES" : "NO");
    var data = {
      minBalance: 0,
      maxBalance: this.kycAmountLimit,
    };
    this.service.showSpinner();
    this.service.post(url, data).subscribe(
      (res: any) => {
        // console.log("j", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          // this.kycRequiredCheckData = res["data"]["resultlist"];
          this.kycStatusChecked();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  KycStatusCheck: any;
  // get kyc check status
  kycStatusChecked() {
    var url = "account/get-check-kyc-status";
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.KycStatusCheck = res.data[0];
          this.text = this.KycStatusCheck.kycIsCheck == "YES" ? true : false;
          this.kycAmountLimit = this.KycStatusCheck.maxBalance;
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // Api of search
  

  search() {

    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}&pageSize=10${this.kycForm.value.userName ? "&search=" + this.kycForm.value.userName: ""}${this.kycForm.value.status? "&kycStatus=" + this.kycForm.value.status: ""}${this.kycForm.value.country ? "&country=" + this.kycForm.value.country : ""}`;
    this.isSearched = true
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        this.kycList = res.data.list;
        // this.totalRecords = res.data.totalCount;
        this.totalRecords = res.data.totalCount;
      },
      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }


  // reset
  reset() {
    this.pageNumber = 1
    this.isSearched = false
    if (
      this.kycForm.value.userName || this.kycForm.value.status || this.kycForm.value.country
    ) {
      this.kycForm.reset({
        userName: "",
        // status: '',
        status: "",
        country: "",
      });
      this.kyc();
    }
  }

  view(id) {
    // console.log("gg", id);
    this.route.navigate(["/kyc-detail"], { queryParams: { id: id } });
  }

  exportAsXLSX() {
    let dataArr = [];
    this.kycList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        Username: element.firstName ? element.firstName : "N/A",
        Email: element.email ? element.email : "N/A",
        "Mobile Number": element.phoneNO ? element.phoneNO : "N/A",
        "Document ID": element.kycId ? element.kycId : "N/A",
        "Date & Time": element.updateTime ? element.updateTime : "N/A",
        // "Date & Time": element.updateTime ? this.transform.transform(element.updateTime, 'medium') : 'N/A',
        Status: element.kycStatus ? element.kycStatus : "N/A",
      });
    });
    // console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "Kyc List");
  }
  sortAgent(key) {
    if (this.isAssending) {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  isSearched : boolean = false
  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if(this.isSearched){
     
      this.search();
    }
    else{
      this.kyc()
    }
    
  }
}
