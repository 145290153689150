<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">USER MANAGEMENT</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="datetime-local" class="form-control form"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="datetime-local" class="form-control form" 
                                                [readonly] = "!this.userForm.controls['startdate'].value"
                                                [min]="userForm.controls['startdate'].value" 
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="datetime" class="form-control w-75"
                                                    placeholder="Username/Email" formControlName="searchText">
                                                <!--  <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="country" class="form-control form1">
                                                    <option value="">Select Country</option>
                                                    <option *ngFor="let code of products" value="{{code.name}}">{{code?.name}}</option>
                                                
                                                   
                                                </select>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="ACTIVE">Active</option>
                                                    <option value="BLOCK">Block</option>
                                                    <option value="UNVERIFIED">Unverified</option>
                                                    <!-- <option value="DELETED">Delete</option> -->
                                                </select>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-9 justify-content-end">
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="text-left" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme cus-mr"
                                                            (click)="pageNumber = 1;getlist()">Search</button>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 7%">
                                                        <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 user-lst p-0" style="margin-top: 18px;display: flex;justify-content: end;padding-right: 21px !important;">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Username <i class="fa fa-sort" (click)="sortAgent('firstName');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Email <i class="fa fa-sort" (click)="sortAgent('email');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <!--         <th scope="col">User ID</th> -->
                                        <th scope="col">Mobile Number <i class="fa fa-sort" (click)="sortAgent('email');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Country <i class="fa fa-sort" (click)="sortAgent('phoneNo');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Status <i class="fa fa-sort" (click)="sortAgent('userStatus');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Registration Date <i class="fa fa-sort" (click)="sortAgent('createTime');isAssending=!isAssending" aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listing?.length">
                                    <tr *ngFor="let item of listing | paginate: { 
                                    itemsPerPage: itemsPerPage,
                                    currentPage: pageNumber,
                                    totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{item?.firstName}}  {{item?.lastName}}</td>
                                        <td>{{item?.email || 'N/A'}}</td>
                                        <!--     <td>{{item?.userId}}</td> -->
                                        <td>{{item?.phoneNo || 'N/A'}}</td>
                                        <td>{{item?.country || 'N/A'}}</td>

                                        <td>{{item?.userStatus || 'N/A'}}</td>

                                        <td>{{item?.createTime | date: 'medium'}}</td>

                                        <td class="action_td_btn3">
                                            <a routerLink="/user-details/{{item.userId}}"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="delete(item.userId)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('ACTIVE',item?.userId)" *ngIf="item?.userStatus=='BLOCK'"
                                                data-toggle="modal"><i class="fa fa-ban" style="color: green;"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('BLOCK',item?.userId)" *ngIf="item?.userStatus=='ACTIVE'"
                                                data-toggle="modal"><i class="fa fa-ban" aria-hidden="true"></i></a>

                                        </td>
                                    </tr>


                                </tbody>
                                <tbody *ngIf="!listing?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5 >No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Token Development ICO Crypto © 2022 All Rights Reserved. <strong
                class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock </h3>
                        <h3 *ngIf="userStatus=='BLOCK'" style="text-align: center; ">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    unblock?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;">Are you sure you want to block?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>