<!-- <div class="wrapper">

  
    <main class="middle-content">
       
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">PRIVACY POLICY</h1>

        </div>

        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row ">
                            <label class="col-md-4">Page Name :</label>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <textarea class="form-control common-textarea" [(ngModel)]="title" readonly></textarea>
                            </div>
                        </div>
                
                    <div class="form-group row ">
                        <label class="col-md-4">Description:</label>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <ck-editor name="editor1" [(ngModel)]="description" debounce="500" skin="moono-lisa"
                                language="en" [fullPage]="true"></ck-editor>
                        </div>
                    </div>

                    <div class="text-center mt40">
                        <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                            (click)="updateLanguageData()">Update</a>
                        <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                            [routerLink]="['/static-content-management']">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
</div>
</main>

</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->

<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">PRIVACY POLICY</h1>
            <hr>
        </div>

        <div class="content-section">
            <div class="order-view mt30 max-WT-800 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                      

                        <div class="form-group row ">
                            <label class="col-md-4">Page Name :</label>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <textarea class="form-control common-textarea" [(ngModel)]="title" readonly></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description :</label>
                         
                        </div>
                        <div class="form-group row">
                            <div  *ngIf="description" class="col-md-12">
                              
                                <ck-editor name="editor1" [(ngModel)]="description" debounce="500" skin="moono-lisa"
                                language="en" [fullPage]="true"></ck-editor>

                          </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                                (click)="updateLanguageData()">Update</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                                [routerLink]="['/static-content-management']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</div>
<footer>
    <div class="container-fluid">
        <p class="copyright">Token Development ICO Crypto © 2022 All Rights Reserved.  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>