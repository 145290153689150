import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-usd-price',
  templateUrl: './usd-price.component.html',
  styleUrls: ['./usd-price.component.css']
})
export class UsdPriceComponent implements OnInit {

  coinList: any=[];
  pageNumber=1;
  cointAmount : any = []
  withdrawAmount : any = []
  constructor(public service:MainService) { }

  ngOnInit(): void {
    this.tradingFeeList()
  }

  tradingFeeList(){
    this.service.get(`wallet/coin/get-coin-list`).subscribe((res:any)=>{
    //  this.coinList=res.data
     let coinArray = ['GRET','GREM']
     let myArray = res.data.filter((ele)=>{
      return coinArray.includes(ele.coinShortName)
     })
     this.coinList=myArray

     myArray.forEach((element,index) => {
      this.cointAmount[index] = false 
    });
      console.log(this.coinList)
    })
  }
  enableInputField(i){
    this.cointAmount.forEach((ele,index)=>{
      if(index == i){
        this.cointAmount[index] = true
      }
      else{
        this.cointAmount[index] = false
      }
    })
  }

  updateCoinAmount(coinName,coinAmount){
    if (!String(coinAmount).length) {


      this.service.toasterErr(`Please enter usd price of ${coinName}`);

      return

    }
    this.service.showSpinner()
      this.service.get(`wallet/admin/fee-management/set-usdt-amount?coinName=${coinName}&usdtAmount=${coinAmount ? coinAmount : 0}`).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message);
        this.tradingFeeList()
        this.service.hideSpinner()
      }
      else{
        this.service.hideSpinner()
      }
    })
  }

}
