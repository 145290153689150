<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">Fee Management</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='MINIMUM_WITHDRAW'}"
                                (click)="selectTab('MINIMUM_WITHDRAW')" href="javascript:;">Minimum Withdrawal
                                Amount</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='WITHDRAW_FEE'}" (click)="selectTab('WITHDRAW_FEE')"
                                href="javascript:;">Withdrawal Fee</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='USD'}" (click)="selectTab('USD')"
                                href="javascript:;">USD Price</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-pane active show" *ngIf="this.currTab == 'MINIMUM_WITHDRAW'">

            <app-min-withdrawl-amount></app-min-withdrawl-amount>
        </div>
        <div class="tab-pane active show" *ngIf="this.currTab == 'WITHDRAW_FEE'">

            <app-standard-trading-fee></app-standard-trading-fee>
        </div>
        <!-- <div class="tab-pane active show" *ngIf="this.currTab == 'USD'">
            <app-usd-price></app-usd-price>

        </div> -->





    </div>
</main>

